module controllers {
    export module manifest {
        interface IConsignmentDetailUpdateScope extends ng.IScope {

        }

        interface IConsignmentDetailUpdateStateParams extends ng.ui.IStateParamsService {
            manId: number;
            codId: number;
            conId: number;
        }

        export class consignmentDetailUpdateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$state', '$stateParams', '$timeout', "bsLoadingOverlayService", "$anchorScroll",
                "consignmentDetailService", "typeOfPackageService", "classificationValueService", "uNDGCodeService"];

            consignmentDetail: interfaces.consignment.IConsignmentDetail;

            breadCrumbDesc: string = "";

            constructor(private $scope: IConsignmentDetailUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $state: ng.ui.IStateService,
                private $stateParams: IConsignmentDetailUpdateStateParams,
                private $timeout: ng.ITimeoutService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private $anchorScroll: ng.IAnchorScrollService,
                private consignmentDetailService: interfaces.consignment.IConsignmentDetailService,
                private typeOfPackageService: interfaces.master.ITypeOfPackageService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private uNDGCodeService: interfaces.applicationcore.IUNDGCodeService) {

                bsLoadingOverlayService.wrap({
                    referenceId: 'cod.update'
                }, () => {
                    return this.loadConsignmentDetail();
                });
            }

            loadConsignmentDetail() {
                return this.consignmentDetailService.get(this.$stateParams.codId, this.$stateParams.conId).get().$promise.then(
                    (result) => {

                        this.consignmentDetail = result;

                        if (result.LineNumber) {
                            this.breadCrumbDesc = "Detail Line " + result.LineNumber;
                        } else {
                            this.breadCrumbDesc = "New Detail Line ";
                        }
                        
                        this.$timeout(() => {

                        });
                    },
                    (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }
                );
            }

            loadTypeOfPackage(searchText: string) {
                return this.typeOfPackageService.getDropdownList(this.consignmentDetail.OwnerEntityId).query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadUnitOfmeasures() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.VolumeUnitOfMeasure
                }, (resultList) => {
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            loadCargoStatusIndicators() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ManifestCargoStatusIndicator
                }, (resultList) => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loaduNDGCodes(searchText: string) {
                return this.uNDGCodeService.getDropdownList(searchText).query(
                    (resultList) => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadPackingGroups() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.PackingGroup
                }, (resultList) => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            //Saves Consignment Detail and returns a promise.
            SaveConsignmentDetail() {
                return this.consignmentDetailService.save().save(this.consignmentDetail).$promise;
            }

            //Submit/Update
            Save() {
                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'cod.update'
                    }, () => {
                        return this.SaveConsignmentDetail().then((result) => {
                            this.$anchorScroll("topAnchor");
                            this.generalService.displayMessageHandler(result);
                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    if (this.$stateParams.codId) {
                                        this.bsLoadingOverlayService.wrap({
                                            referenceId: 'cod.update'
                                        }, () => {
                                            return this.loadConsignmentDetail();
                                        });
                                    } else {
                                        this.$state.params["codId"] = Number(result.ID);
                                        this.$state.go(this.$state.current.name, this.$state.params, { reload: this.$state.current.name });
                                    }
                                });
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            this.$anchorScroll("topAnchor");
                        });
                    });
                });
            }

            SaveClose() {
                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'cod.update'
                    }, () => {
                        return this.SaveConsignmentDetail().then((result) => {
                            this.$anchorScroll("topAnchor");
                            this.generalService.displayMessageHandler(result);
                            if (!result.HasErrorMessage) {
                                this.$state.go("^");
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            this.$anchorScroll("topAnchor");
                        });
                    });
                });
            }

            Close() {
                this.$state.go("^");
            }
        };

        angular.module("app").controller("consignmentDetailUpdateCtrl", controllers.manifest.consignmentDetailUpdateCtrl);
    }
}